import { SIGN_OUT_FULFILLED } from '../actions/signOut';
import {
  SELECT_MULTIPLE_PAYMENT_ACCOUNTS,
  SelectedPaymentAccount
} from '../actions/selectMultiplePaymentAccounts';
import { SELECT_PAYMENT_AMOUNT } from '../actions/selectPaymentAmount';
import { SELECT_BILLING_ACCOUNT_PENDING } from '../actions/selectBillingAccount';

export default function paymentSelectionReducer(
  state: SelectedPaymentAccount[] | null = null,
  action: any
): SelectedPaymentAccount[] | null {
  switch (action.type) {
    case SELECT_MULTIPLE_PAYMENT_ACCOUNTS:
      return action.payload;
    case SELECT_PAYMENT_AMOUNT:
      return action.payload;
    case SELECT_BILLING_ACCOUNT_PENDING:
      return null;
    case SIGN_OUT_FULFILLED:
      return null;
    default:
      return state;
  }
}
