import axios from 'axios';

export const GET_BILLING_ACCOUNTS_BALANCE = 'GET_BILLING_ACCOUNTS_BALANCE';
export const GET_BILLING_ACCOUNTS_BALANCE_FULFILLED = `${GET_BILLING_ACCOUNTS_BALANCE}_FULFILLED`;
export const GET_BILLING_ACCOUNTS_BALANCE_REJECTED = `${GET_BILLING_ACCOUNTS_BALANCE}_REJECTED`;

export function getBillingAccountsBalance(financialAccountIds: string[]): any {
  const params = new URLSearchParams();
  financialAccountIds.forEach(financialAccountId => {
    params.append('financialAccountIds', financialAccountId.substring(1));
  });
  return {
    type: GET_BILLING_ACCOUNTS_BALANCE,
    payload: axios.get(
      `${process.env.REACT_APP_BILLING_ACCOUNTS_BY_FINANCIAL_IDS_SERVICE_ENDPOINT}`,
      {
        params
      }
    )
  };
}
