export const DISPLAY_SEARCH_RESULTS = 'DISPLAY_SEARCH_RESULTS';

export interface DisplaySearchResultAction {
  type: typeof DISPLAY_SEARCH_RESULTS;
  payload: {
    searchText: string;
    searchIn: string[];
  };
}

export function displaySearchResults(
  searchText: string,
  searchIn: string[]
): DisplaySearchResultAction {
  return {
    type: DISPLAY_SEARCH_RESULTS,
    payload: {
      searchText,
      searchIn
    }
  };
}
