import { SIGN_IN_FULFILLED } from '../actions/signIn';
import { SIGN_OUT_FULFILLED, SIGN_OUT_REJECTED } from '../actions/signOut';

export interface SignInInfo {
  customerId: string;
  email: string;
  userName: string;
  sessionId: string;
}

export default function signInInfoReducer(
  state: SignInInfo | null = null,
  action: any
): SignInInfo | null {
  switch (action.type) {
    case SIGN_IN_FULFILLED: {
      const { data } = action.payload;
      if (data.statusCode === '1004') {
        return null;
      }
      if (data.results) {
        const { authToken, refreshToken, sessionId } = data.results;
        sessionStorage.setItem('authToken', authToken);
        sessionStorage.setItem('refreshToken', refreshToken);
        sessionStorage.setItem('sessionId', sessionId);
        return {
          customerId: data.results.customerId,
          email: data.results.email,
          sessionId,
          userName: data.results.userName
        };
      }
      return state;
    }
    case SIGN_OUT_FULFILLED:
    case SIGN_OUT_REJECTED: {
      if (action.payload?.statusCode === '1004') {
        return null;
      }
      sessionStorage.clear();
      if (action.meta?.signOutType === 'timeOut') {
        window.document.location = '/login?sessionTimeout=true';
      } else if (action.meta?.signOutType === 'error') {
        return null;
      } else {
        window.document.location = '/login?logout=true';
      }
      return null;
    }
    default:
      return state;
  }
}
