import {
  GET_BILLING_PROFILE_FULFILLED,
  GET_BILLING_PROFILE_PENDING
} from '../actions/getBillingProfile';
import {
  SAVE_BILLING_PROFILE_CONTACT_FULFILLED,
  SAVE_BILLING_PROFILE_CONTACT_REJECTED
} from '../actions/saveBillingName';
import { BillingContact } from '../components/BillingName';
import {
  SAVE_BILLING_PROFILE_EMAIL_FULFILLED,
  SAVE_BILLING_PROFILE_EMAIL_REJECTED
} from '../actions/saveBillingEmail';
import { BillingProfileAddress } from '../components/BillingAddress';
import { BillingProfileEmail } from '../components/BillingEmail';
import {
  SAVE_BILLING_ADDRESS_FULFILLED,
  SAVE_BILLING_ADDRESS_REJECTED
} from '../actions/saveBillingAddress';

interface BillingProfileResponse {
  messageId?: string;
  messages?: [
    {
      args?: {
        additionalProp1?: string;
        additionalProp2?: string;
        additionalProp3?: string;
      };
      cmsContentId?: string;
      cmsField?: string;
      cmsTemplate?: string;
      defaultMessage?: string;
      type?: string;
    }
  ];
  results: {
    baId: string;
    billingAddress: null | BillingProfileAddress;
    billingContact: null | BillingContact;
    billingEmail: null | BillingProfileEmail;
    contactId?: string;
  };
  statusCode: string;
  statusDescription: string;
}

export interface BillingProfile {
  billingAddress: BillingProfileAddress;
  billingContact: BillingContact | null;
  billingEmail: BillingProfileEmail | null;
  status: 'INITIAL' | 'PENDING' | 'FULFILLED' | 'REJECTED';
}

export interface BillingProfiles {
  [key: string]: BillingProfile;
}
export default function billingProfileReducer(
  state: null | BillingProfiles = null,
  action: any
): null | BillingProfiles {
  switch (action.type) {
    case GET_BILLING_PROFILE_PENDING: {
      const { baId } = action.meta;
      return {
        ...state,
        [baId]: {
          status: 'PENDING',
          billingContact: null,
          billingEmail: null,
          billingAddress: {
            addressLineOne: '',
            zip: '',
            state: '',
            city: ''
          }
        }
      };
    }
    case GET_BILLING_PROFILE_FULFILLED: {
      const { data } = action.payload;
      if (data.statusCode === '1004') {
        return null;
      }
      const { results }: BillingProfileResponse = data;

      const billingProfile: BillingProfile = {
        status: 'FULFILLED',
        billingAddress: results?.billingAddress
          ? {
              addressLineOne: results.billingAddress.addressLineOne || '',
              addressLineTwo: results.billingAddress.addressLineTwo || '',
              city: results.billingAddress.city || '',
              state: results.billingAddress.state || '',
              zip: results.billingAddress.zip || ''
            }
          : {
              addressLineOne: '',
              addressLineTwo: '',
              city: '',
              state: '',
              zip: ''
            },
        billingEmail: results?.billingEmail,
        billingContact: results?.billingContact
      };
      const { baId } = action.meta;
      if (state) {
        return { ...state, [baId]: billingProfile };
      }
      return { [baId]: billingProfile };
    }

    case SAVE_BILLING_PROFILE_CONTACT_FULFILLED: {
      const { data } = action.payload;
      const { baId } = action.meta;
      if (state) {
        const billingProfile: BillingProfile = {
          ...state[baId],
          billingContact: {
            ...data.results,
            billingContactStatus: { status: 'SUCCESS' }
          }
        };
        return { ...state, [baId]: billingProfile };
      }
      return state;
    }

    case SAVE_BILLING_PROFILE_EMAIL_FULFILLED: {
      const { data } = action.payload;
      const { baId } = action.meta;
      if (state) {
        const billingProfile: BillingProfile = {
          ...state[baId],
          billingEmail: {
            ...data.results,
            billingEmailStatus: { status: 'SUCCESS' }
          }
        };
        return { ...state, [baId]: billingProfile };
      }
      return state;
    }

    case SAVE_BILLING_ADDRESS_FULFILLED: {
      if (state) {
        return {
          ...state,
          [action.meta.baId]: {
            ...state[action.meta.baid],
            billingAddress: {
              ...action.payload?.data?.results,
              billingAddressStatus: {
                status: 'SUCCESS'
              }
            }
          }
        };
      }
      return state;
    }

    case SAVE_BILLING_PROFILE_CONTACT_REJECTED: {
      if (state) {
        if (action.payload?.response?.data) {
          return {
            ...state,
            [action.meta.baId]: {
              ...state[action.meta.baId],
              billingContact: {
                ...state[action.meta.baId].billingContact,

                billingContactStatus: {
                  status: 'FAILED',
                  messages: [
                    { type: 'error', cmsContentId: 'save-contact-error' }
                  ]
                }
              }
            }
          };
        }
      }
      return state;
    }

    case SAVE_BILLING_ADDRESS_REJECTED: {
      if (state) {
        if (action.payload?.response?.data) {
          const { data } = action.payload.response;
          if (data.statusCode === '1000') {
            return {
              ...state,
              [action.meta.baId]: {
                ...state[action.meta.baId],
                billingAddress: {
                  ...state[action.meta.baId].billingAddress,
                  billingAddressStatus: {
                    status: 'INVALID',
                    messages: [{}],
                    suggestedAddress: data.results,
                    enteredAddress: action.meta.billingAddress
                  }
                }
              }
            };
          }

          return {
            ...state,
            [action.meta.baId]: {
              ...state[action.meta.baId],
              billingAddress: {
                ...state[action.meta.baId].billingAddress,

                billingAddressStatus: {
                  status: 'FAILED',
                  messages: [
                    { type: 'error', cmsContentId: 'address-not-found' }
                  ],
                  enteredAddress: action.meta.billingAddress
                }
              }
            }
          };
        }
      }
      return state;
    }

    case SAVE_BILLING_PROFILE_EMAIL_REJECTED: {
      if (state) {
        if (action.payload?.response?.data) {
          return {
            ...state,
            [action.meta.baId]: {
              ...state[action.meta.baId],
              billingEmail: {
                ...state[action.meta.baId].billingEmail,
                billingEmailStatus: {
                  status: 'FAILED',
                  messages: [
                    { type: 'error', cmsContentId: 'save-email-error' }
                  ]
                }
              }
            }
          };
        }
      }
      return state;
    }
    case 'CLEAR_ADDRESS_STATUS': {
      if (state) {
        return {
          ...state,
          [action.meta.baId]: {
            ...state[action.meta.baId],
            billingAddress: {
              ...state[action.meta.baId].billingAddress,

              billingAddressStatus: undefined
            }
          }
        };
      }
      return state;
    }

    default:
      return state;
  }
}
