import axios from 'axios';

export const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY';
export const GET_PAYMENT_HISTORY_FULFILLED = `${GET_PAYMENT_HISTORY}_FULFILLED`;
export const GET_PAYMENT_HISTORY_REJECTED = `${GET_PAYMENT_HISTORY}_REJECTED`;

export function getPaymentHistory(
  financialAccountId: string,
  noOfDays: number
): any {
  const params = new URLSearchParams();
  params.append('noOfDays', `${noOfDays}`);
  return {
    type: GET_PAYMENT_HISTORY,
    payload: axios.get(
      `${process.env.REACT_APP_PAYMENT_HISTORY_SERVICE_ENDPOINT}/${financialAccountId}`,
      { params }
    )
  };
}
