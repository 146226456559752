import { GET_SECURITY_CODE_FULFILLED } from '../actions/getSecurityCode';
import { SAVE_SECURITY_CODE_INFO_FULFILLED } from '../actions/saveSecurityCodeInfo';
import { SecurityCodeResponseType } from '../types/SecurityPinType';

export default function securityCodeReducer(
  state: null | SecurityCodeResponseType = null,
  action: any
): null | SecurityCodeResponseType {
  switch (action.type) {
    case GET_SECURITY_CODE_FULFILLED: {
      const { data } = action.payload;
      if (data.statusCode === '1004') {
        return null;
      }

      if (data.results) {
        const response: SecurityCodeResponseType = data.results;
        const securityCode: SecurityCodeResponseType = {
          ...response,
          securityPin: ''
        };
        if (response.hasPin === 'Yes') {
          securityCode.securityPin = '****';
        }
        return securityCode;
      }
      return state;
    }
    case SAVE_SECURITY_CODE_INFO_FULFILLED:
      if (state) {
        return { ...state, ...action.payload.data.results };
      }
      return null;
    default:
      return state;
  }
}
