import axios from 'axios';

export const GET_PAYMENT_RECEIPTS = 'GET_PAYMENT_RECEIPTS';
export const GET_PAYMENT_RECEIPTS_FULFILLED = `${GET_PAYMENT_RECEIPTS}_FULFILLED`;
export const GET_PAYMENT_RECEIPTS_REJECTED = `${GET_PAYMENT_RECEIPTS}_REJECTED`;

export function getPaymentReceipts(): any {
  return {
    type: GET_PAYMENT_RECEIPTS,
    payload: axios.get(
      `${process.env.REACT_APP_PAYMENT_RECEIPTS_SERVICE_ENDPOINT}`
    )
  };
}
