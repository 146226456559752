import { combineReducers } from 'redux';
import userName from './userNameStatusReducer';
import password from './passwordStatusReducer';
import signIn from './signInStatusReducer';
import securityCode from './securityCodeStatusReducer';

const statusReducers: any = {
  userName,
  password,
  signIn,
  securityCode
};

const status: any = combineReducers(statusReducers);

export default status;
