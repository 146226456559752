import React from 'react';
import { AkashaApp, TemplateContent } from 'akasha-app-components';
import { withRouter } from 'react-router-dom';
import {
  Anchor,
  CampfireButton,
  Card,
  CopyToClipboard,
  DisplayField,
  Formatter,
  InfoBanner,
  InstructionsPanel,
  SectionToggle,
  TextInput,
  Tooltip
} from 'mydish-campfire-components';
import 'akasha-app-components/css/bootstrap/bootstrap-grid.css';
import { useDispatch, useSelector } from 'react-redux';
import signOut from './actions/signOut';
import getConfig from './actions/getConfig';
import Loading from './components/Loading';
import { store } from './configureStore';

const SignIn = React.lazy(() => import('./components/SignInContainer'));
const BillingAccounts = React.lazy(() =>
  import('./components/BillingAccountsContainer')
);
const BillingAccountDetails = React.lazy(() =>
  import('./components/BillingAccountDetailsContainer')
);
const MultiPaymentAccountSelection = React.lazy(() =>
  import('./components/MultiPaymentAccountSelectionContainer')
);
const PaymentReceipts = React.lazy(() =>
  import('./components/PaymentReceiptsContainer')
);
const Ebill = React.lazy(() => import('./components/EBillContainer'));
const AutoPay = React.lazy(() => import('./components/AutoPayContainer'));
const FindByEmail = React.lazy(() =>
  import('./components/FindByEmailContainer')
);
const VerifyEmailToken = React.lazy(() =>
  import('./components/VerifyEmailTokenContainer')
);
const UserName = React.lazy(() => import('./components/UsernameContainer'));
const Password = React.lazy(() => import('./components/PasswordContainer'));
const CyberSourcePayment = React.lazy(() =>
  import('./components/CyberSourcePaymentContainer')
);
const PaymentHistory = React.lazy(() =>
  import('./components/PaymentHistoryContainer')
);
const PaymentAmountSelection = React.lazy(() =>
  import('./components/PaymentAmountSelectionContainer')
);
const BillHistory = React.lazy(() =>
  import('./components/BillHistoryContainer')
);
const Search = React.lazy(() => import('./components/Search'));
const GetBillingAccountsFailed = React.lazy(() =>
  import('./components/GetBillingAccountsFailed')
);
const BillingEmail = React.lazy(() =>
  import('./components/BillingEmailContainer')
);
const SecurityCode = React.lazy(() =>
  import('./components/SecurityCodeContainer')
);

const BillingAddress = React.lazy(() =>
  import('./components/BillingAddressContainer')
);

const BillingName = React.lazy(() =>
  import('./components/BillingNameContainer')
);

function App(props: any) {
  const signInInfo = useSelector((state: any) => state.signInInfo);
  const config = useSelector((state: any) => state.config);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!config) {
      dispatch(getConfig());
    }
  }, []);

  const timeOutTimer = () => {
    const startTimer = () => {
      return setTimeout(() => {
        store.dispatch(signOut('timeOut'));
      }, 19 * 60 * 1000); // 19minutes
    };

    let timer = startTimer();

    const resetTimer = () => {
      clearTimeout(timer);
      timer = startTimer();
    };
    document.addEventListener('click', resetTimer);
    document.addEventListener('keydown', resetTimer);
    document.addEventListener('scroll', resetTimer);
  };

  React.useEffect(() => {
    if (signInInfo) {
      timeOutTimer();
    }
  }, [signInInfo]);

  return (
    <>
      {config && (
        <>
          <Loading />
          <AkashaApp
            history={props.history}
            logo="/logo.png"
            pageUrl={props.url}
            footerLogo="/cms-files/logos/DISHCHARCOAL.svg"
            signInUrl="/login"
            signInLabel="Log In"
            signOutLabel="Log Out"
            isLoggedIn={signInInfo != null && signInInfo.customerId != null}
            globalMessages={[]}
            navigationTheme="night"
            appName="Business Care App"
            customNavigationTemplate="businesscare-topnav-links"
            customNavigation={
              signInInfo?.customerId
                ? undefined
                : [{ name: '', pageName: '', pageURL: '' }]
            }
            footerLinksTemplate="businesscare-footer-links"
            additionalHeaders={{ MSessionId: signInInfo?.sessionId }}
            footerCopyrightMessage={
              <TemplateContent
                templateName="businesscare-campfire-contents"
                templateFieldName="message"
                contentId="copyright"
                language="en"
              />
            }
            footContent={<></>}
            onSignOut={() => {
              dispatch(signOut());
            }}
            components={{
              Card,
              TextInput,
              InfoBanner,
              InstructionsPanel,
              DisplayField,
              Anchor,
              Formatter,
              CopyToClipboard,
              CampfireButton,
              Tooltip,
              SectionToggle,
              SignIn,
              CyberSourcePayment,
              BillingAccounts,
              BillingAccountDetails,
              MultiPaymentAccountSelection,
              PaymentReceipts,
              Ebill,
              AutoPay,
              FindByEmail,
              VerifyEmailToken,
              UserName,
              Password,
              PaymentAmountSelection,
              PaymentHistory,
              BillHistory,
              Search,
              GetBillingAccountsFailed,
              BillingEmail,
              BillingAddress,
              BillingName,
              SecurityCode
            }}
          />
        </>
      )}
    </>
  );
}

export default withRouter(App);
