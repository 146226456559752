import { combineReducers } from 'redux';
import { akashaReducer } from 'mydish-ui-util';
import signInInfo from './reducers/signInInfoReducer';
import config from './reducers/configReducer';
import billingAccounts from './reducers/billingAccountsReducer';
import selectedBillingAccount from './reducers/selectedBillingAccountReducer';
import selectedPaymentAccounts from './reducers/paymentSelectionReducer';
import paymentReceipts from './reducers/paymentReceiptsReducer';
import { SIGN_OUT_FULFILLED } from './actions/signOut';
import userName from './reducers/userNameReducer';
import status from './reducers/statusReducer';
import paymentHistory from './reducers/paymentHistoryReducer';
import billHistory from './reducers/billHistoryReducer';
import searchSuggestions from './reducers/searchSuggestionsReducer';
import paymentHistorySearchState from './reducers/paymentHistorySearchReducer';
import billHistorySearchState from './reducers/billHistorySearchReducer';
import loadingState from './reducers/loadingReducer';
import faIds from './reducers/faIdReducer';
import billingProfiles from './reducers/billingProfileReducer';
import securityCodeInfo from './reducers/securityCodeReducer';

const appReducer = combineReducers({
  akasha: akashaReducer,
  config,
  signInInfo,
  billingAccounts,
  selectedBillingAccount,
  selectedPaymentAccounts,
  paymentReceipts,
  userName,
  status,
  paymentHistory,
  billHistory,
  searchSuggestions,
  paymentHistorySearchState,
  billHistorySearchState,
  loadingState,
  faIds,
  billingProfiles,
  securityCodeInfo
});
const rootReducer = (state: any, action: any) => {
  if (action.type === SIGN_OUT_FULFILLED) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
