import axios from 'axios';

export const SAVE_PASSWORD = 'SAVE_PASSWORD';
export const SAVE_PASSWORD_FULFILLED = `${SAVE_PASSWORD}_FULFILLED`;
export const SAVE_PASSWORD_PENDING = `${SAVE_PASSWORD}_PENDING`;
export const SAVE_PASSWORD_REJECTED = `${SAVE_PASSWORD}_REJECTED`;

export function savePassword(password: string): any {
  return {
    type: SAVE_PASSWORD,
    payload: axios.put(`${process.env.REACT_APP_SIGNININFO_SERVICE_ENDPOINT}`, {
      password
    })
  };
}
