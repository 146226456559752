import axios from 'axios';

export const GET_USER_NAME = 'GET_USER_NAME';
export const GET_USER_NAME_FULFILLED = `${GET_USER_NAME}_FULFILLED`;
export const GET_USER_NAME_REJECTED = `${GET_USER_NAME}_REJECTED`;

export function getUserName(): any {
  return {
    type: GET_USER_NAME,
    payload: axios.get(`${process.env.REACT_APP_SIGNININFO_SERVICE_ENDPOINT}`)
  };
}
