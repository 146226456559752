import axios from 'axios';
import { BillingProfileEmail } from '../components/BillingEmail';

export const SAVE_BILLING_PROFILE_EMAIL = 'SAVE_BILLING_PROFILE_EMAIL';
export const SAVE_BILLING_PROFILE_EMAIL_FULFILLED = `${SAVE_BILLING_PROFILE_EMAIL}_FULFILLED`;
export const SAVE_BILLING_PROFILE_EMAIL_REJECTED = `${SAVE_BILLING_PROFILE_EMAIL}_REJECTED`;
export const SAVE_BILLING_PROFILE_EMAIL_PENDING = `${SAVE_BILLING_PROFILE_EMAIL}_PENDING`;

export function saveBillingEmail(
  baId: string,
  billingEmail: BillingProfileEmail
) {
  return {
    type: SAVE_BILLING_PROFILE_EMAIL,
    payload: axios.post(
      `${process.env.REACT_APP_BILLING_PROFILE_UPDATE_EMAIL_SERVICE_ENDPOINT}/${baId}`,
      billingEmail
    ),
    meta: { baId }
  };
}
