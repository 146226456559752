import { GET_FA_IDS_FULFILLED } from '../actions/getFAIds';
import { SIGN_OUT_FULFILLED, SIGN_OUT_REJECTED } from '../actions/signOut';

export default function faIdReudcer(
  state: null | string[] = null,
  action: any
): null | string[] {
  switch (action.type) {
    case GET_FA_IDS_FULFILLED: {
      const { data } = action.payload;
      if (data.results.fiancialAccountIds) {
        return data.results.fiancialAccountIds;
      }
      return data.results;
    }
    case SIGN_OUT_FULFILLED:
    case SIGN_OUT_REJECTED:
      return null;
    default:
      return state;
  }
}
