import { GET_CONFIG_FULFILLED } from '../actions/getConfig';

export default function configReducer(state = null, action: any) {
  switch (action.type) {
    case GET_CONFIG_FULFILLED:
      return action.payload.data;
    default:
      return state;
  }
}
