// @ts-nocheck
import 'url-search-params-polyfill';
import axios, { AxiosRequestConfig } from 'axios';
import { v4 as uuidV4 } from 'uuid';
import { getMarketID } from './marketID';

axios.defaults.headers.common['Customer-Facing-Tool'] =
  process.env.REACT_APP_CFT;
axios.defaults.headers.common.CFT = process.env.REACT_APP_CFT;
axios.defaults.headers.common['Interaction-ID'] = uuidV4();
axios.defaults.headers.common['Market-ID'] = getMarketID();
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
// Add a request interceptor
const interceptorFunction = (config: AxiosRequestConfig) => {
  const modifiedConfig = { ...config };
  if (sessionStorage.getItem('authToken')) {
    modifiedConfig.headers.AUTHJWT = sessionStorage.getItem('authToken');
  }
  if (sessionStorage.getItem('refreshToken')) {
    modifiedConfig.headers.REFRESH_TOKEN = sessionStorage.getItem(
      'refreshToken'
    );
  }
  if (sessionStorage.getItem('sessionId')) {
    modifiedConfig.headers.MSessionId = sessionStorage.getItem('sessionId');
  }
  return modifiedConfig;
};

axios.interceptors.request.use(interceptorFunction);

// Add a response interceptor

axios.interceptors.response.use(
  response => {
    if (response.headers.authjwt) {
      sessionStorage.setItem('authToken', response.headers.authjwt);
    }
    return Promise.resolve(response);
  },
  error => {
    if (error.response && error.response.status === 401) {
      sessionStorage.clear();
      window.document.location = `/login`;
    }
    return Promise.reject(error);
  }
);
