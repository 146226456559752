import axios from 'axios';

export const GET_BILLING_PROFILE = 'GET_BILLING_PROFILE';
export const GET_BILLING_PROFILE_FULFILLED = `${GET_BILLING_PROFILE}_FULFILLED`;
export const GET_BILLING_PROFILE_REJECTED = `${GET_BILLING_PROFILE}_REJECTED`;
export const GET_BILLING_PROFILE_PENDING = `${GET_BILLING_PROFILE}_PENDING`;

export function getBillingProfile(baId: string, faId: string): any {
  return {
    type: GET_BILLING_PROFILE,
    payload: axios.get(
      `${process.env.REACT_APP_BILLING_PROFILE_SERVICE_ENDPOINT}/${baId}/${faId}`
    ),
    meta: { baId }
  };
}
