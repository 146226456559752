import { StatusType } from '../types/commonTypes';
import {
  SAVE_USER_NAME_FULFILLED,
  SAVE_USER_NAME_PENDING,
  SAVE_USER_NAME_REJECTED
} from '../actions/saveUsername';
import { CANCEL_CHANGES } from '../actions/cancelChanges';

export default function userNameStatusReducer(
  state: StatusType = { status: 'INITIAL' },
  action: any
): StatusType {
  switch (action.type) {
    case SAVE_USER_NAME_FULFILLED: {
      return {
        status: 'SUCCESS',
        messages: action.payload && action.payload.messages
      };
    }
    case SAVE_USER_NAME_PENDING: {
      return {
        status: 'PROCESSING'
      };
    }
    case SAVE_USER_NAME_REJECTED: {
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.data
      ) {
        const { data } = action.payload.response;
        return {
          status: 'FAILED',
          messages: data.messages || [
            {
              type: 'error',
              cmsContentId: 'system-error-message'
            }
          ]
        };
      }
      return {
        status: 'FAILED',
        messages: [
          {
            type: 'error',
            cmsContentId: 'system-error-message'
          }
        ]
      };
    }
    case CANCEL_CHANGES: {
      if (action.payload.widget === 'username') {
        return { ...state, status: 'INITIAL' };
      }
      return state;
    }
    default:
      return state;
  }
}
