import axios from 'axios';

export const SAVE_USER_NAME = 'SAVE_USER_NAME';
export const SAVE_USER_NAME_PENDING = `${SAVE_USER_NAME}_PENDING`;
export const SAVE_USER_NAME_FULFILLED = `${SAVE_USER_NAME}_FULFILLED`;
export const SAVE_USER_NAME_REJECTED = `${SAVE_USER_NAME}_REJECTED`;

export function saveUserName(userName: string): any {
  return {
    type: SAVE_USER_NAME,
    payload: axios.put(`${process.env.REACT_APP_SIGNININFO_SERVICE_ENDPOINT}`, {
      userName
    })
  };
}
