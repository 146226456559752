import { GET_PAYMENT_RECEIPTS_FULFILLED } from '../actions/getPaymentReceipts';
import { SELECT_MULTIPLE_PAYMENT_ACCOUNTS } from '../actions/selectMultiplePaymentAccounts';
import { SELECT_PAYMENT_AMOUNT } from '../actions/selectPaymentAmount';

export interface Receipt {
  status: string;
  billingArrangementName: string;
  billingArrangementId: string;
  financialAccountId: string;
  orderNumber: string;
  receiptDate: string;
  totalAmount: number;
  payMethodType: string;
  payMethodDigits: string;
  payMethodExpMonth: string;
  payMethodExpYear: string;
  payMethodBrand: string;
  payMethodBrandName: string;
}

export default function paymentReceiptsReducer(
  state: Receipt[] | null = null,
  action: any
): Receipt[] | null {
  switch (action.type) {
    case SELECT_MULTIPLE_PAYMENT_ACCOUNTS:
    case SELECT_PAYMENT_AMOUNT:
      return null;
    case GET_PAYMENT_RECEIPTS_FULFILLED: {
      const { data } = action.payload;
      if (data.statusCode === '1004') {
        return null;
      }
      return data.results;
    }
    default:
      return state;
  }
}
