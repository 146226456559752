export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';

export interface ClearSearchResultsAction {
  type: typeof CLEAR_SEARCH_RESULTS;
  payload: {
    searchIn: string[];
  };
}

export function clearSearchResult(
  searchIn: string[]
): ClearSearchResultsAction {
  return {
    type: CLEAR_SEARCH_RESULTS,
    payload: {
      searchIn
    }
  };
}
