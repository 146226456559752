import {
  GetSearchSuggestionsAction,
  FETCH_SEARCH_SUGGESTIONS
} from '../actions/getSearchSuggestions';
import {
  DisplaySearchResultAction,
  DISPLAY_SEARCH_RESULTS
} from '../actions/displaySearchResults';
import {
  ClearSearchResultsAction,
  CLEAR_SEARCH_RESULTS
} from '../actions/clearSearchResults';

export interface BillHistorySearchState {
  searchText: string;
  maxSuggestions: number;
  display: 'suggestions' | 'result' | 'clear';
}

export default function billHistorySearchReducer(
  state: BillHistorySearchState = {
    searchText: '',
    maxSuggestions: 5,
    display: 'clear'
  },
  action:
    | GetSearchSuggestionsAction
    | DisplaySearchResultAction
    | ClearSearchResultsAction
): BillHistorySearchState {
  switch (action.type) {
    case FETCH_SEARCH_SUGGESTIONS: {
      if (action.payload.searchIn.includes('billhistory')) {
        return {
          searchText: action.payload.searchText,
          maxSuggestions: action.payload.maxSuggestions,
          display: 'suggestions'
        };
      }
      return state;
    }
    case DISPLAY_SEARCH_RESULTS: {
      if (action.payload.searchIn.includes('billhistory')) {
        return {
          searchText: action.payload.searchText,
          maxSuggestions: state.maxSuggestions,
          display: 'result'
        };
      }
      return state;
    }
    case CLEAR_SEARCH_RESULTS: {
      if (action.payload.searchIn.includes('billhistory')) {
        return {
          searchText: '',
          maxSuggestions: state.maxSuggestions,
          display: 'clear'
        };
      }
      return state;
    }
    default:
      return state;
  }
}
