import axios from 'axios';

export const GET_DUE_DATES = 'GET_DUE_DATES';
export const GET_DUE_DATES_FULFILLED = `${GET_DUE_DATES}_FULFILLED`;
export const GET_DUE_DATES_REJECTED = `${GET_DUE_DATES}_REJECTED`;

export function getDueDates(billingAccountIds: string[]): any {
  const params = new URLSearchParams();
  billingAccountIds.forEach(billingAccountId => {
    params.append('baIds', billingAccountId);
  });
  return {
    type: GET_DUE_DATES,
    payload: axios.get(
      `${process.env.REACT_APP_BILLING_ACCOUNTS_BY_BILLING_IDS_SERVICE_ENDPOINT}`,
      {
        params
      }
    )
  };
}
