export const FETCH_SEARCH_SUGGESTIONS = 'FETCH_SEARCH_SUGGESTIONS';

export interface GetSearchSuggestionsAction {
  type: typeof FETCH_SEARCH_SUGGESTIONS;
  payload: {
    searchText: string;
    maxSuggestions: number;
    searchIn: string[];
  };
}

export function getSearchSuggestions(
  inputValue: string,
  searchIn: string[],
  maxSuggestions: number
): GetSearchSuggestionsAction {
  return {
    type: FETCH_SEARCH_SUGGESTIONS,
    payload: {
      searchText: inputValue,
      maxSuggestions,
      searchIn
    }
  };
}
