import { GET_BILLING_ACCOUNTS_REJECTED } from '../actions/getBillingAccounts';
import {
  SIGN_IN_FULFILLED,
  SIGN_IN_PENDING,
  SIGN_IN_REJECTED
} from '../actions/signIn';
import { SIGN_OUT_FULFILLED } from '../actions/signOut';

export default function signInStatusReducer(
  state: 'INITIAL' | 'PROCESSING' | 'SUCCESS' | 'FAILED' = 'INITIAL',
  action: any
): 'INITIAL' | 'PROCESSING' | 'SUCCESS' | 'FAILED' {
  switch (action.type) {
    case SIGN_IN_PENDING: {
      return 'PROCESSING';
    }
    case SIGN_IN_REJECTED: {
      return 'FAILED';
    }
    case SIGN_IN_FULFILLED: {
      const { data } = action.payload;
      if (data.statusCode === '1004') {
        return 'FAILED';
      }
      if (data.results) {
        return 'SUCCESS';
      }
      return 'FAILED';
    }
    case SIGN_OUT_FULFILLED: {
      return 'INITIAL';
    }
    case GET_BILLING_ACCOUNTS_REJECTED: {
      return 'INITIAL';
    }
    default:
      return state;
  }
}
