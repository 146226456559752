import axios from 'axios';
import { BillingProfileAddress } from '../components/BillingAddress';

export const SAVE_BILLING_ADDRESS = 'SAVE_BILLING_ADDRESS';
export const SAVE_BILLING_ADDRESS_FULFILLED = `${SAVE_BILLING_ADDRESS}_FULFILLED`;
export const SAVE_BILLING_ADDRESS_REJECTED = `${SAVE_BILLING_ADDRESS}_REJECTED`;
export const SAVE_BILLING_ADDRESS_PENDING = `${SAVE_BILLING_ADDRESS}_PENDING`;

export function saveBillingAddress(
  baId: string,
  billingAddress: BillingProfileAddress
): any {
  return {
    type: SAVE_BILLING_ADDRESS,
    payload: axios.post(
      `${process.env.REACT_APP_BILLING_PROFILE_UPDATE_ADDRESS_SERVICE_ENDPOINT}/${baId}`,
      billingAddress
    ),
    meta: { baId, billingAddress }
  };
}
