import React from 'react';
import { Loader } from 'mydish-campfire-components';
import { useDispatch, useSelector } from 'react-redux';

export default function Loading() {
  const loading = useSelector((state: any) => state.loadingState > 0);
  const dispatch = useDispatch();

  const startTimer = () => {
    return global.setTimeout(() => {
      dispatch({ type: 'FORCE_RESET_LOADER' });
    }, 20000);
  };

  React.useEffect(() => {
    let timer;

    if (loading) {
      timer = startTimer();
    } else {
      clearTimeout(timer);
    }
  }, [loading]);
  return <>{loading && <Loader />}</>;
}
