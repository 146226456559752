import {
  SAVE_SECURITY_CODE_INFO_FULFILLED,
  SAVE_SECURITY_CODE_INFO_PENDING,
  SAVE_SECURITY_CODE_INFO_REJECTED
} from '../actions/saveSecurityCodeInfo';
import { CANCEL_CHANGES } from '../actions/cancelChanges';
import { MessageType } from '../types/commonTypes';

export interface SecurityCodeStatusType {
  securityCode?: string;
  status: 'INITIAL' | 'PROCESSING' | 'MODIFIED' | 'FAILED' | 'SUCCESS';
  messages?: MessageType[];
}

export default function securityCodeStatusReducer(
  state: SecurityCodeStatusType = { status: 'INITIAL' },
  action: any
): SecurityCodeStatusType {
  switch (action.type) {
    case SAVE_SECURITY_CODE_INFO_FULFILLED:
      return {
        status: 'SUCCESS',
        messages: action.payload && action.payload.messages
      };

    case SAVE_SECURITY_CODE_INFO_PENDING: {
      return {
        status: 'PROCESSING'
      };
    }
    case SAVE_SECURITY_CODE_INFO_REJECTED: {
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.data
      ) {
        const { data } = action.payload.response;
        if (data.statusCode === '1000') {
          return {
            status: 'MODIFIED',
            messages: data.messages
          };
        }
        return {
          status: 'FAILED',
          messages: data.messages || [
            {
              type: 'error',
              cmsContentId: 'security-pin-update-failed'
            }
          ]
        };
      }
      return {
        status: 'FAILED',
        messages: [
          {
            type: 'error',
            cmsContentId: 'system-error-message'
          }
        ]
      };
    }
    case CANCEL_CHANGES: {
      if (action.payload.widget === 'security-code') {
        return { ...state, status: 'INITIAL' };
      }
      return state;
    }
    default:
      return state;
  }
}
