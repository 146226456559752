import axios from 'axios';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_PENDING = `${SIGN_IN}_PENDING`;
export const SIGN_IN_FULFILLED = `${SIGN_IN}_FULFILLED`;
export const SIGN_IN_REJECTED = `${SIGN_IN}_REJECTED`;

export interface SignInRequest {
  username: string;
  password: string;
  rememberMeCheck: boolean;
  captchaToken: string;
}

export function signIn(signInRequest: SignInRequest): any {
  return {
    type: SIGN_IN,
    payload: axios.post(
      `${process.env.REACT_APP_SIGNIN_SERVICE_ENDPOINT}`,
      {
        username: signInRequest.username,
        password: signInRequest.password
      },
      {
        headers: {
          'captcha-token': signInRequest.captchaToken,
          'captcha-flow': 'LOGIN'
        }
      }
    )
  };
}
