import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './configureStore';
import {} from './utils/axiosEnrichment';

ReactDOM.render(
  <Suspense fallback={<div></div>}>
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route
            path="/*"
            render={(props: any) => (
              <App
                url={
                  // eslint-disable-next-line
                  props.location.pathname
                }
              />
            )}
          />
        </PersistGate>
      </Provider>
    </Router>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
